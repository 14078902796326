import React from 'react';
import { withGoogleMap, GoogleMap, withScriptjs } from 'react-google-maps';

const GoogleMapWrapped = withScriptjs(
  withGoogleMap(({ position }) => <GoogleMap options={{ disableDefaultUI: true }} defaultZoom={17} defaultCenter={position} />)
);

export default ({ url, ...props }) => (
  <GoogleMapWrapped
    googleMapURL={url}
    loadingElement={<div style={{ width: '100%', height: '100%' }} />}
    containerElement={<div style={{ width: '100%', height: '100%', marginBottom: 20 }} />}
    mapElement={<div style={{ width: '100%', height: '100%' }} />}
    {...props}
  />
);
