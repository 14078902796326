import React from 'react';
import SVG from 'react-inlinesvg';
import { navigateTo, graphql } from 'gatsby';
import { Card } from 'antd';
import shuffle from 'lodash.shuffle';
import { Icon as LegacyIcon } from '@ant-design/compatible';

import Layout from '../components/layout';
import GoogleMaps from '../components/googlemap';
import styles from './index.module.css';
import { scrollToElement } from '../utils';

export default ({ data: { news, site, places } }) => {
  const { mainImage } = news.edges[0].node;
  const { googlemaps, address } = site.siteMetadata;
  const { name, coord, street, city } = address.find(({ name }) => name.includes('Straßburg-Passage'));

  return (
    <Layout>
      <div className={styles.banner} style={{ backgroundImage: 'url("/home_1.jpg")' }}>
        <SVG className={styles.svg} src="/logo.svg" uniquifyIDs={false}>
          <img alt="Straßburg-Passage logo" src="/logo.png" />
        </SVG>
        <h1>Stilvoll einkaufen in historischem Ambiente</h1>
      </div>
      <div className={styles.row}>
        <Card
          className={styles.card}
          onClick={() => navigateTo('/angebote')}
          hoverable
          cover={
            <div className={styles.preview}>
              <ul>
                {shuffle(places.edges).reduce(
                  (red, { node: { icon, header } }) =>
                    icon && icon.file
                      ? [
                          ...red,
                          <li key={header}>
                            <img alt={header} src={icon.file.url} />
                          </li>,
                        ]
                      : red,
                  []
                )}
              </ul>
            </div>
          }
        >
          <Card.Meta title="ANGEBOTE" description="Erkunden Sie alle Angebote in einer interaktiven Karte" />
        </Card>

        <Card
          onClick={() => {
            // navigateTo('/news-veranstaltungen');
            typeof window !== 'undefined' && window.open('https://www.facebook.com/strassburgpassage', '_blank').focus();
          }}
          className={styles.card}
          hoverable
          cover={
            <div className={styles.preview} style={{ backgroundImage: `url("${mainImage && mainImage.file ? mainImage.file.url : '/placeholder.jpg'}")` }} />
          }
        >
          <Card.Meta
            title={
              <div>
                <LegacyIcon type="facebook" style={{ marginRight: 4 }} /> NEUIGKEITEN
              </div>
            }
            description={'Erfahren Sie Neuigkeiten rund um die Straßburg-Passage auf Facebook'}
          />
          {/* <Card.Meta title="NEUIGKEITEN" description={header} /> */}
        </Card>
        <Card
          onClick={() => scrollToElement('#kontakt')}
          className={styles.card}
          hoverable
          cover={
            <div className={styles.preview}>
              <GoogleMaps position={coord} url={googlemaps} />
            </div>
          }
        >
          <Card.Meta
            title="KONTAKT"
            description={
              <div className={styles.contact}>
                <span>{name}</span>
                <span>{street}</span>
                <span>{city}</span>
              </div>
            }
          />
        </Card>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        googlemaps
        address {
          name
          street
          city
          coord {
            lat
            lng
          }
        }
      }
    }
    news: allContentfulNeuigkeiten(sort: { fields: [date], order: DESC }, limit: 1) {
      edges {
        node {
          header
          date
          mainImage {
            file {
              url
            }
          }
        }
      }
    }
    places: allContentfulAngebote {
      edges {
        node {
          header
          icon {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
